import { useAuth0 } from '@auth0/auth0-react';
import { getParkingAreas } from 'api/maps';
import Alert from 'components/common/alert';
import ButtonText from 'components/common/buttonText';
import ButtonToggle from 'components/common/buttonToggle';
import InputDateAndTime from 'components/common/inputDateAndTime/inputDateAndTime';
import ParkingSpotMap from 'components/common/parkingSpotMap';
import Widget from 'components/common/widget';
import React, { useEffect, useState, useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as BookingsWhiteIcon } from 'assets/icons/ico_electricity_white.svg';
import { ReactComponent as BookingsBlackIcon } from 'assets/icons/ico_electricity_black.svg';
import { ReactComponent as CalendarIcon } from 'assets/icons/ico_calendar.svg';
import { getPriceForParkingArea } from 'api/pricing';
import { getFixedEndTime, getFixedStartTime } from 'helpers/timeHelper';
import { useMessages } from 'context/messages';
import InputText from 'components/common/inputText';
import { createVehicle } from 'api/vehicles';
import { bookParkingArea, bookParkingSpot } from 'api/bookings';
import { payBooking } from 'api/payments';
import { useParams } from 'react-router-dom';
import { isValid } from 'date-fns';
import debounce from 'lodash/debounce';

const BookPage = () => {
    const { t } = useTranslation();
    const { organizationExtId } = useParams();
    const { getAccessTokenSilently } = useAuth0();
    const [startDate, setStartDate] = useState(new Date());
    const [mapPosition, setMapPosition] = useState();
    const [parkingAreas, setParkingAreas] = useState();
    const [selectedParkingArea, setSelectedParkingArea] = useState();
    /* Set default value to today's date + 1 day */
    const [endDate, setEndDate] = useState(() => {
        const now = new Date();
        const todayPlusOneHour = new Date(now.getFullYear(), now.getMonth(), now.getDate(), now.getHours() + 1, now.getMinutes(), 0, 0);
        return todayPlusOneHour;
    });
    const [price, setPrice] = useState({});
    const [hasChargingPost, setHasChargingPost] = useState(false);
    const [bookingStartDate, setBookingStartDate] = useState();
    const [bookingEndDate, setBookingEndDate] = useState();
    const { showToast } = useMessages();
    const [bookingRequest, setBookingRequest] = useState();
    const [isLoadingPrice, setIsLoadingPrice] = useState(false);
    const [isCreatingBooking, setIsCreatingBooking] = useState(false);
    const [booking, setBooking] = useState();

    const onStartDateChange = (startDate) => {
        if(!isValid(startDate)) return

        setStartDate(startDate)

        validateDates(startDate, endDate);
    }

    const onEndDateChange = (endDate) => {
        if(!isValid(endDate)) return
        
        setEndDate(endDate)

        validateDates(startDate, endDate);
    }

    const debouncedValidate = useRef(
        debounce((startDate, endDate) => {
            if (endDate && endDate <= startDate) {
                handleEndDate(startDate, endDate);
            }
        }, 1000)
    );

    const validateDates = useCallback((startDate, endDate) => {
        debouncedValidate.current.cancel();
        debouncedValidate.current(startDate, endDate);
    }, []);

    const showToastMessage = () => (
        showToast(t('bookPage.invalidInputDateTitle'), t('bookPage.invalidInputDateDescription'), 'info')
    )

    const handleEndDate = (startDate, endDate) => {
        const defaultEndTimeCookie = document.cookie.split('; ').find(row => row.startsWith('defaultEndTime'));
        
        if (defaultEndTimeCookie) {
            const defaultEndTimeCookieValue = defaultEndTimeCookie.split('=')[1];
            
            if (!defaultEndTimeCookie) {
                return;
            }

            const endDateFromCookieValue = new Date(endDate);
            
            endDateFromCookieValue.setDate(startDate.getDate() + 1);
            endDateFromCookieValue.setHours(defaultEndTimeCookieValue.split(':')[0]);
            endDateFromCookieValue.setMinutes(defaultEndTimeCookieValue.split(':')[1]);
            
            setEndDate(endDateFromCookieValue);
        } else {
            const startDatePlusOneHour = new Date(
                startDate.getFullYear(),
                startDate.getMonth(),
                startDate.getDate(),
                startDate.getHours() + 1,
                startDate.getMinutes(), 0, 0);
                
            setEndDate(startDatePlusOneHour);
        }
        
        showToastMessage()
    }

    useEffect(() => {
        const load = async () => {
            try {
                const token = await getAccessTokenSilently();
                const result = await getParkingAreas(token, {
                    latitude: mapPosition.lat,
                    longitude: mapPosition.lng,
                    distanceInMeters: 1000,
                    isIndoor: false,
                    hasChargingPost: hasChargingPost,
                    isFenced: false,
                    startDateTime: startDate,
                    endDateTime: endDate
                });

                if (result && !result.error) {
                    setParkingAreas(result);
                } else {
                    showToast(t('generic.errorTitle'), t('generic.errorDescription'), 'error');
                }
            } catch (error) {
                console.error(error);
            }
        }

        if (mapPosition && startDate < endDate) {
            load();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mapPosition, startDate, endDate, hasChargingPost]);


    useEffect(() => {
        const defaultStartTimeCookie = document.cookie.split('; ').find(row => row.startsWith('defaultStartTime'));
        const defaultEndTimeCookie = document.cookie.split('; ').find(row => row.startsWith('defaultEndTime'));

        if (defaultStartTimeCookie && defaultEndTimeCookie) {
            const defaultStartTimeCookieValue = defaultStartTimeCookie.split('=')[1];
            const defaultEndTimeCookieValue = defaultEndTimeCookie.split('=')[1];

            if (defaultStartTimeCookieValue > defaultEndTimeCookieValue) {

                if (defaultStartTimeCookieValue) {
                    const startDateFromCookieValue = new Date(startDate);
                    startDateFromCookieValue.setHours(defaultStartTimeCookieValue.split(':')[0]);
                    startDateFromCookieValue.setMinutes(defaultStartTimeCookieValue.split(':')[1]);
                    setStartDate(startDateFromCookieValue);
                }

                if (defaultEndTimeCookieValue) {
                    const endDateFromCookieValue = new Date(endDate);
                    endDateFromCookieValue.setDate(startDate.getDate() + 1);
                    endDateFromCookieValue.setHours(defaultEndTimeCookieValue.split(':')[0]);
                    endDateFromCookieValue.setMinutes(defaultEndTimeCookieValue.split(':')[1]);
                    setEndDate(endDateFromCookieValue);
                }
            } else {

                if (defaultStartTimeCookieValue) {
                    const startDateFromCookieValue = new Date(startDate);
                    startDateFromCookieValue.setHours(defaultStartTimeCookieValue.split(':')[0]);
                    startDateFromCookieValue.setMinutes(defaultStartTimeCookieValue.split(':')[1]);
                    setStartDate(startDateFromCookieValue);
                }

                if (defaultEndTimeCookieValue) {
                    const endDateFromCookieValue = new Date(endDate);
                    endDateFromCookieValue.setHours(defaultEndTimeCookieValue.split(':')[0]);
                    endDateFromCookieValue.setMinutes(defaultEndTimeCookieValue.split(':')[1]);
                    setEndDate(endDateFromCookieValue);
                }
            }
        }
    }, []);

    useEffect(() => {
        const getPrice = async () => {
            try {
                setIsLoadingPrice(true);
                setBookingRequest(null);
                setBooking(null);
                const { start, end } = getBookingDates();
                setBookingStartDate(start);
                setBookingEndDate(end);
                const token = await getAccessTokenSilently();
                const result = await getPriceForParkingArea(token, {
                    startDateTime: start,
                    endDateTime: end,
                    parkingAreaExtId: selectedParkingArea.parkingAreaExtId,
                    hasChargingPost: hasChargingPost,
                });
                setPrice(result);
            } catch (error) {
                console.error(error);
            } finally {
                setIsLoadingPrice(false);
            }
        }

        if (selectedParkingArea) {
            getPrice();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedParkingArea, startDate, endDate, hasChargingPost]);

    const getBookingDates = () => {

        const start = new Date(startDate);
        const end = new Date(endDate);

        const fixedStartTime = getFixedStartTime(start, selectedParkingArea);

        if (fixedStartTime) {
            start.setHours(fixedStartTime.split(':')[0]);
            start.setMinutes(fixedStartTime.split(':')[1]);
        }

        let fixedEndTime = getFixedEndTime(end, selectedParkingArea);

        /* If fixed end time is later than fixed start time, and it's the same day, we need to add one day and check if the next day has a fixed end time */
        if (fixedEndTime < fixedStartTime && start.getDate() === end.getDate()) {
            end.setDate(end.getDate() + 1);
            fixedEndTime = getFixedEndTime(end, selectedParkingArea);
        } else if (end.getHours().toString().padStart(2, '0') + ':' + end.getMinutes().toString().padStart(2, '0') + ':00' > fixedEndTime) {
            end.setDate(end.getDate() + 1);
            fixedEndTime = getFixedEndTime(end, selectedParkingArea);
        }

        if (fixedEndTime) {
            end.setHours(fixedEndTime.split(':')[0]);
            end.setMinutes(fixedEndTime.split(':')[1]);
        }

        return { start, end };
    }

    const getFormattedDate = (startDate, endDate) => {

        if (startDate.getDate() === endDate.getDate()) {
            return `
            ${startDate.toLocaleDateString('en-GB', {
                day: '2-digit',
                month: 'short',
                hour: '2-digit',
                minute: '2-digit'
            })} - ${endDate.toLocaleTimeString('en-GB', {
                hour: '2-digit',
                minute: '2-digit'
            })}`;
        }

        const start = startDate?.toLocaleDateString('en-GB', {
            day: '2-digit',
            month: 'short',
            hour: '2-digit',
            minute: '2-digit'
        });

        const end = endDate?.toLocaleDateString('en-GB', {
            day: '2-digit',
            month: 'short',
            hour: '2-digit',
            minute: '2-digit'
        });

        return `${start} - ${end}`;
    }

    const handleCreateBooking = async () => {
        try {

            // Require a vehicle
            if (!bookingRequest.vehicleIdentificationNumber) {
                showToast(t('bookPage.vehicleIdentificationNumberRequiredTitle'), t('bookPage.vehicleIdentificationNumberRequiredDescription'), 'error');
                return;
            }

            // Validate email
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

            if (!bookingRequest.email || !emailRegex.test(bookingRequest.email)) {
                showToast(t('bookPage.invalidEmailTitle'), t('bookPage.invalidEmailDescription'), 'error');
                return;
            }

            setIsCreatingBooking(true);
            const token = await getAccessTokenSilently();
            const vehicleResult = await createVehicle(token, {
                vehicleTypeId: 1,
                vehicleIdentification: 'FrontDesk vehicle',
                registrationIdentifier: bookingRequest.vehicleIdentificationNumber,
                ...bookingRequest
            });

            if (vehicleResult?.error) {
                console.error(vehicleResult);
                showToast(t('generic.errorTitle'), t('generic.errorDescription'), 'error');
                return;
            }

            let bookingResult;

            if (price.parkingSpotExtId) {
                bookingResult = await bookParkingSpot(token, {
                    parkingSpotExtId: price.parkingSpotExtId,
                    vehicleExtId: vehicleResult.vehicleExtId,
                    startDateTime: bookingStartDate,
                    endDateTime: bookingEndDate,
                    extendedFirstName: bookingRequest.firstName,
                    extendedLastName: bookingRequest.lastName,
                    extendedEmail: bookingRequest.email,
                    extendedPhone: bookingRequest.phone,
                    extendedOrganizationExtId: organizationExtId
                });
            } else {
                bookingResult = await bookParkingArea(token, {
                    parkingAreaExtId: selectedParkingArea.parkingAreaExtId,
                    vehicleExtId: vehicleResult.vehicleExtId,
                    startDateTime: bookingStartDate,
                    endDateTime: bookingEndDate,
                    hasChargingPost: hasChargingPost,
                    extendedFirstName: bookingRequest.firstName,
                    extendedLastName: bookingRequest.lastName,
                    extendedEmail: bookingRequest.email,
                    extendedPhone: bookingRequest.phone,
                    extendedOrganizationExtId: organizationExtId
                });
            }

            if (bookingResult.error) {
                console.error(bookingResult);
                if (bookingResult.error === 'PARKING_UNAVAILABLE') {
                    showToast(t('bookPage.parkingUnavailableTitle'), t('bookPage.parkingUnavailableDescription'), 'error');
                } else {
                    showToast(t('generic.errorTitle'), t('generic.errorDescription'), 'error');
                }
                return;
            }

            const paymentResult = await payBooking(token, {
                bookingExtId: bookingResult.bookingExtId,
            });

            if (paymentResult && !paymentResult.error) {
                setBooking(paymentResult);
            } else {
                console.error(paymentResult);
                showToast(t('generic.errorTitle'), t('generic.errorDescription'), 'error');
            }

        } catch (error) {
            console.error(error);
            showToast(t('generic.errorTitle'), t('generic.errorDescription'), 'error');
        } finally {
            setIsCreatingBooking(false);
        }
    }

    const handleBookingComplete = () => {
        setBooking(null);
        setBookingRequest(null);
        setSelectedParkingArea(null);
        setPrice(null);
    }

    return (
        <div className="flex flex-col gap-y-6">
            <Widget>
                <h1 className="text-3xl font-medium mb-6">{t('bookPage.title')}</h1>
                <div className="flex flex-col gap-y-6">
                    <div className="flex flex-row gap-x-3">
                        <InputDateAndTime
                            title={t('generic.from')}
                            value={startDate}
                            onChange={(value) => { onStartDateChange(value) }} />
                        <InputDateAndTime
                            title={t('generic.to')}
                            min={new Date(startDate).toISOString().split("T")[0]}
                            value={endDate}
                            onChange={(value) => { onEndDateChange(value) }} />
                    </div>
                    <div className="flex flex-col">
                        <ButtonToggle
                            title={t('generic.chargingPost')}
                            defaultIcon={<BookingsBlackIcon />}
                            toggledIcon={<BookingsWhiteIcon />}
                            isToggled={hasChargingPost}
                            onClick={() => { setHasChargingPost(!hasChargingPost) }} />
                    </div>
                    <ParkingSpotMap
                        parkingAreas={parkingAreas ?? []}
                        setSelectedParkingArea={setSelectedParkingArea}
                        mapPosition={mapPosition}
                        setMapPosition={setMapPosition} />
                </div>
            </Widget>
            {!selectedParkingArea && !booking &&
                <Alert
                    title={t('bookPage.selectParkingAreaTitle')}
                    description={t('bookPage.selectParkingAreaDescription')}
                    template='info' />
            }
            {selectedParkingArea && !price?.error && bookingStartDate && bookingEndDate && !bookingRequest && !booking &&
                <Widget>
                    <div className="flex justify-center items-center flex-col">
                        <h2 className="text-2xl font-medium pb-1">{selectedParkingArea.streetAddress}</h2>
                        <span className="text-base">{selectedParkingArea.name}</span>
                        {selectedParkingArea.price &&
                            <span className="text-base">{selectedParkingArea.price?.friendlyPrice} / {t(`unit.${selectedParkingArea.price?.priceUnit}`)}</span>
                        }
                        {selectedParkingArea.isPassageEnabled &&
                            <div className="flex max-w-md w-full mt-6">
                                <Alert
                                    title={t('bookPage.requiresAirPassTitle')}
                                    description={t('bookPage.requiresAirPassDescription')}
                                    template='info'></Alert>
                            </div>
                        }
                    </div>

                    <div className="flex flex-col justify-center items-center mt-6">
                        <div className="flex gap-x-1 mb-3">
                            <CalendarIcon className="h-5" />
                            <span className="font-medium">
                                {getFormattedDate(bookingStartDate, bookingEndDate)}
                            </span>
                        </div>
                        <span className="text-2xl font-medium mb-6">{price?.friendlyPrice}</span>
                        <ButtonText
                            disabled={price?.error || isLoadingPrice}
                            isLoading={isLoadingPrice}
                            className="max-w-xs w-full"
                            onClick={() => {
                                setBookingRequest(
                                    {
                                        parkingArea: selectedParkingArea,
                                        price: price,
                                        bookingStartDate: bookingStartDate,
                                        bookingEndDate: bookingEndDate
                                    })
                            }}>
                            {t('generic.next')}
                        </ButtonText>
                    </div>
                </Widget>
            }
            {bookingRequest && !booking &&
                <Widget>
                    <div className="flex justify-center items-center flex-col">
                        <h2 className="text-2xl font-medium pb-1">{bookingRequest.parkingArea.streetAddress}</h2>
                        <span className="text-base">{bookingRequest.parkingArea.name}</span>
                    </div>
                    {bookingRequest.price.parkingSpotIdentifier &&
                        <div className="flex items-center justify-center mt-6">
                            <div className="flex flex-col bg-airpark-oil-500 rounded-2xl shadow-lg h-24 w-24 items-center justify-center">
                                <span className="text-white">
                                    {t('bookPage.parkingSpot')}
                                </span>
                                <span className="text-white text-xl">
                                    {bookingRequest.price.parkingSpotIdentifier}
                                </span>
                            </div>
                        </div>
                    }
                    <div className="flex flex-col items-center justify-center mt-6">
                        <div className="flex gap-x-1 mb-3">
                            <CalendarIcon className="h-5" />
                            <span className="font-medium">
                                {getFormattedDate(bookingStartDate, bookingEndDate)}
                            </span>
                        </div>
                        <span className="text-2xl font-medium">{price?.friendlyPrice}</span>
                    </div>
                    <div className="flex flex-col w-full items-center justify-center mt-6">
                        <div className="flex flex-col max-w-xs w-full gap-y-3">
                            <InputText
                                title={t('bookPage.firstName')}
                                placeHolder={t('bookPage.firstNamePlaceHolder')}
                                value={bookingRequest.firstName ?? ''}
                                onChange={(value) => { setBookingRequest({ ...bookingRequest, firstName: value }) }} />
                            <InputText
                                title={t('bookPage.lastName')}
                                placeHolder={t('bookPage.lastNamePlaceHolder')}
                                value={bookingRequest.lastName ?? ''}
                                onChange={(value) => { setBookingRequest({ ...bookingRequest, lastName: value }) }} />
                            <InputText
                                title={t('bookPage.vehicleIdentificationNumber')}
                                placeHolder='ABC123'
                                value={bookingRequest.vehicleIdentificationNumber ?? ''}
                                onChange={(value) => { setBookingRequest({ ...bookingRequest, vehicleIdentificationNumber: value.toUpperCase() }) }} />
                            <InputText
                                title={t('bookPage.phone')}
                                placeHolder='+01-555-1234'
                                value={bookingRequest.phone ?? ''}
                                onChange={(value) => { setBookingRequest({ ...bookingRequest, phone: value }) }} />
                            <InputText
                                title={t('bookPage.email')}
                                placeHolder='jane.doe@email.com'
                                value={bookingRequest.email ?? ''}
                                onChange={(value) => { setBookingRequest({ ...bookingRequest, email: value }) }} />
                        </div>
                    </div>
                    <div className="flex flex-col items-center justify-center mt-12">
                        <div className="flex flex-col gap-y-3 max-w-xs w-full">
                            <ButtonText
                                isLoading={isCreatingBooking}
                                disabled={isCreatingBooking}
                                onClick={() => { handleCreateBooking(); }}>{t('bookPage.book')}</ButtonText>
                            <ButtonText
                                isSecondary={true}
                                disabled={isCreatingBooking}
                                onClick={() => { setBookingRequest(null); }}>
                                {t('bookPage.cancel')}
                            </ButtonText>
                        </div>
                    </div>
                </Widget>
            }
            {price?.error && !selectedParkingArea.isFree && !booking &&
                <Widget>
                    <div className="flex justify-center items-center flex-col mb-6">
                        <h2 className="text-2xl font-medium pb-1">{selectedParkingArea.streetAddress}</h2>
                        <span className="text-base">{selectedParkingArea.name}</span>
                    </div>
                    <Alert
                        title={t('bookPage.selectedParkingAreaUnavailableForSelectedTimePeriodTitle')}
                        description={t('bookPage.selectedParkingAreaUnavailableForSelectedTimePeriodDescription')}
                        template='warning' />
                </Widget>
            }
            {price?.error && selectedParkingArea.isFree && !booking &&
                <Widget>
                    <div className="flex justify-center items-center flex-col">
                        <h2 className="text-2xl font-medium pb-1">{selectedParkingArea.streetAddress}</h2>
                        <span className="text-base">{selectedParkingArea.name}</span>
                    </div>
                    <Alert
                        title={t('bookPage.selectedparkingAreaIsFreeForSelectedTimePeriodTitle')}
                        description={t('bookPage.selectedparkingAreaIsFreeForSelectedTimePeriodDescription')}
                        template='success' />
                </Widget>
            }
            {booking && bookingRequest && price && selectedParkingArea &&
                <Widget>
                    <Alert
                        title={t('bookPage.bookingSuccessTitle')}
                        description={t('bookPage.bookingSuccessDescription')}
                        template='success' />
                    <div className="flex justify-center items-center flex-col mt-6">
                        <h2 className="text-2xl font-medium pb-1">{selectedParkingArea.streetAddress}</h2>
                        <span className="text-base">{booking.parkingAreaName}</span>
                    </div>
                    {bookingRequest.price.parkingSpotIdentifier &&
                        <div className="flex items-center justify-center mt-6">
                            <div className="flex flex-col bg-airpark-oil-500 rounded-2xl shadow-lg h-24 w-24 items-center justify-center">
                                <span className="text-white">
                                    {t('bookPage.parkingSpot')}
                                </span>
                                <span className="text-white text-xl">
                                    {booking.spotIdentifier}
                                </span>
                            </div>
                        </div>
                    }
                    <div className="flex flex-col items-center justify-center mt-6">
                        <div className="flex gap-x-1 mb-3">
                            <CalendarIcon className="h-5" />
                            <span className="font-medium">
                                {getFormattedDate(new Date(booking.startDateTime), new Date(booking.endDateTime))}
                            </span>
                        </div>
                        <span className="text-2xl font-medium">{price?.friendlyPrice}</span>
                    </div>
                    <div className="flex flex-col items-center justify-center mt-12">
                        <div className="flex flex-col gap-y-3 max-w-xs w-full">
                            <ButtonText
                                onClick={() => { handleBookingComplete(null); }}>
                                {t('generic.ok')}
                            </ButtonText>
                        </div>
                    </div>
                </Widget>
            }
        </div>
    );
};

export default BookPage;