import { useAuth0 } from '@auth0/auth0-react';
import { getInvoices } from 'api/invoices';
import { getOrganizationForUser } from 'api/organization';
import ButtonText from 'components/common/buttonText';
import Widget from 'components/common/widget';
import WidgetLoadingSkeleton from 'components/common/widgetLoadingSkeleton';
import InvoicesTable from 'components/pageSpecific/invoices/invoicesTable';
import { useMessages } from 'context/messages';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import Alert from 'components/common/alert';

const HomePage = () => {
    const { t } = useTranslation();
    const [isLoading, setIsLoading] = useState(true);
    const { getAccessTokenSilently } = useAuth0();
    const { organizationExtId } = useParams();
    const { showToast } = useMessages();
    const [invoices, setInvoices] = useState();
    const [organization, setOrganization] = useState();

    useEffect(() => {
        const load = async () => {
            try {
                const token = await getAccessTokenSilently();
                const invoicesResult = await getInvoices(token, organizationExtId);
                const organizationResult = await getOrganizationForUser(token, organizationExtId);

                if (invoicesResult.error) { 
                    console.error(invoicesResult);
                    showToast(t('generic.errorTitle'), t('generic.errorDescription'), 'error');
                } else {
                    setInvoices(invoicesResult);
                }

                if (organizationResult.error) {
                    console.error(organizationResult);
                    showToast(t('generic.errorTitle'), t('generic.errorDescription'), 'error');
                } else {
                    setOrganization(organizationResult);
                }

                console.log(invoicesResult);
            } catch (error) {
                console.error(error);
            } finally {
                setIsLoading(false);
            }
        }

        load();
    }, [organizationExtId]);

    function downloadDetailedCSV(data) {
        let csvContent = "";
    
        data.forEach(invoice => {
            csvContent += `\nInvoice ID: ${invoice.invoiceExtId}, Due Date: ${invoice.dueDate}, Invoice Date: ${invoice.invoiceDate}, Total Amount: ${invoice.amountIncVat}, Total VAT: ${invoice.vat}\n`;
    
            csvContent += "First Name,Last Name,Email,Phone Number,Parking Area,Parking Spot,Start Date Time,End Date Time,Amount Inc VAT,VAT\n";
    
            invoice.invoiceLines.forEach(line => {
                const row = [
                    line.firstName || "",
                    line.lastName || "",
                    line.email || "",
                    line.phoneNumber || "",
                    line.parkingArea || "",
                    line.parkingSpot || "",
                    line.startDateTime || "",
                    line.endDateTime || "",
                    line.amountIncVat.toString() || "",
                    line.vat.toString() || ""
                ].join(",");
                csvContent += row + "\n";
            });
        });
    
        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.setAttribute("href", url);
        link.setAttribute("download", "airpark_frontdesk_invoices.csv");
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    return (
        <div className="flex flex-col gap-y-6">
            {isLoading &&
                <WidgetLoadingSkeleton />
            }
            {!isLoading && organization &&
                <>
                    <Widget>
                        <h2 className="text-xl font-medium pb-1">{t('homePage.title', { name: organization.name })}</h2>
                        <span className="text-base">{t('homePage.description')}</span>
                    </Widget>
                    <div className="flex flex-col gap-x-6">
                        {invoices?.length > 0 &&
                        <>
                            <Alert
                            title={t('invoicesTable.openCsvTitle')}
                            description={t('invoicesTable.openCsvDescription')}
                            template='transparent-info' 
                            /> 
                        <Widget>
                                <div className="flex justify-between items-center mb-6">
                                    <h2 className="text-xl font-medium">{t('homePage.latestInvoices')}</h2>
                                    <ButtonText onClick={() => downloadDetailedCSV(invoices)} className='max-w-[240px]'>{t('homePage.downloadCSV')}</ButtonText>
                                </div>
                                <InvoicesTable
                                    invoices={invoices} />
                            </Widget></>
                        }
                        {invoices?.length === 0 &&
                            <Widget>
                                <h2 className="text-xl font-medium pb-1">{t('homePage.latestInvoices')}</h2>
                                <p>{t('homePage.noInvoicesDescription')}</p>
                            </Widget>
                        }
                    </div>
                </>
            }

        </div>
    );
};

export default HomePage;