import React, { useState, useContext } from "react";

export const MessagesContext = React.createContext({});
export const useMessages = () => useContext(MessagesContext);

export const MessagesProvider = ({ children }) => {
  const [toast, setToast] = useState({ display: false, title: '', description: '', template: '', id: null });
  
  const showToast = (title, description, template) => {
    if (toast.display) {
      // HACK: trigger state change if the toast has been shown
      setToast({ display: false });

      setTimeout(() => {
        setToast({ display: true, title: title, description: description, template: template, id: Date.now() });
      }, 50);

    } else {
      setToast({ display: true, title: title, description: description, template: template, id: Date.now() });
    }
  }

  const hideToast = () => {
    setToast({ ...toast, display: false });
  }

  return (
    <MessagesContext.Provider
      value={{
        toast,
        showToast,
        hideToast,
      }}>
      {children}
    </MessagesContext.Provider>
  );
}