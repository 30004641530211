import { useMessages } from "context/messages";
import React, { useEffect, useRef } from "react";
import { ReactComponent as SuccessIcon } from 'assets/icons/ico_thumbs_up_green.svg';
import { ReactComponent as InfoIcon } from 'assets/icons/ico_info_circle_blue.svg';
import { ReactComponent as CloseIcon } from 'assets/icons/ico_menu_close.svg';
import { ReactComponent as ErrorIcon } from 'assets/icons/ico_sad_smiley_red.svg';
import { useLocation } from "react-router-dom";

const Toast = () => {
    const { toast, hideToast } = useMessages();
    const toastRef = useRef();
    const location = useLocation();

    useEffect(() => {
        // Hide toast when navigation occurred
        hideToast();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location]); 
    
    if (toast && toast.display) {
        return (
            <div key={toast.id} className={`flex z-50 min-h-[96px] max-h-48 items-center justify-center fixed w-full bottom-[-200px] pb-safe animate-slide-in-bottom`}>
                <div ref={toastRef} onClick={() => { hideToast(); }} className="flex relative items-center pl-11 pr-11 pt-4 pb-4 md:w-[728px] rounded-xl w-80 min-h-[96px] max-h-48 shadow bg-white">
                    <div className="md:flex hidden mr-8">
                        {toast.template === 'info' &&
                            <InfoIcon className="w-8 h-8"  />
                        }
                        {toast.template === 'error' &&
                            <ErrorIcon className="w-8 h-8" />
                        }
                        {toast.template === 'success' &&
                            <SuccessIcon className="w-8 h-8"  />
                        }
                    </div>
                    <div className="flex flex-col max-w-full">
                        <span className="font-medium text-lg">{toast.title}</span>
                        <span className="font-normal text-base break-words">{toast.description}</span>
                    </div>
                    <button className="flex absolute right-9 top-6" onClick={(e) => { e.preventDefault(); hideToast(); }}><CloseIcon className="h-5 w-5" /></button>
                </div>
            </div>);
    }
    else {
        return (null);
    }
}

export default Toast;