import React from "react";
import { format, parse } from 'date-fns';

const InputDateAndTime = ({ title, value, onChange, className, min }) => {

    const baseClassName = 'border-airpark-gray-300 hover:border-airpark-gray-400 border-2 h-11 rounded-xl pl-2 pr-2 text-airpark-green font-medium text-center last:ml-2 focus:outline-none';

    if (!className) {
        className = baseClassName;
    } else {
        className = `${baseClassName} ${className}`;
    }

    const handleDateChange = (date) => {
        if (!date) {
            return;
        }
        const updatedDateString = `${date} ${format(value, 'HH:mm')}`;
        const updatedDate = parse(updatedDateString, 'yyyy-MM-dd HH:mm', new Date());
        onChange(updatedDate);
    }

    const handleTimeChange = (time) => {
        if (!time) return;

        const updatedDateString = `${format(value, 'yyyy-MM-dd')} ${time}`;
        const updatedDate = parse(updatedDateString, 'yyyy-MM-dd HH:mm', new Date());

        onChange(updatedDate);
    }

    return (
        <div>
            <span className="pb-1 font-medium">{title}</span>
            <div className="flex flex-row">
                <input value={format(value, 'yyyy-MM-dd')} min={min} onChange={(e) => { handleDateChange(e.target.value); }} type="date" className={className} />
                <input value={format(value, 'HH:mm')} onChange={(e) => { handleTimeChange(e.target.value)}} type="time" className={className} />
            </div>
        </div>
    )
}

export default InputDateAndTime;